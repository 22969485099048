<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.expense_approval") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td><employee-profile :employee="row.item.employee" /></td>
              <td><employee-profile :employee="row.item.reporter" /></td>
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.description || "-" }}</td>
              <td>
                <template v-if="row.item.travel">
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-account</v-icon>
                    {{ row.item.travel.employee.firstName }} {{ row.item.travel.employee.lastName }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-map-marker</v-icon>{{ row.item.travel.address }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-calendar-range</v-icon>
                    {{ row.item.travel.beginDate | formattedDateTimeMinute }} -
                    {{ row.item.travel.endDate | formattedDateTimeMinute }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td>
                <template v-if="row.item.expenseManagement">
                  <p class="ma-0">
                    {{ row.item.expenseManagement.name }}
                  </p>
                  <p v-if="row.item.expenseManagement.project" class="ma-0 caption">
                    <v-avatar size="14" :color="row.item.expenseManagement.project.color" />
                    {{ row.item.expenseManagement.project.name }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td class="text-no-wrap">
                <v-chip
                  class="mr-1"
                  :key="index"
                  small
                  v-for="(currency, index) in getConvertedCurrencies(row.item.allRequested)"
                >
                  {{ currency }}
                </v-chip>
              </td>
              <td>
                <request-status-label :status="row.item.requestStatus" />
              </td>
              <td class="text-right">
                <v-btn small depressed color="primary" dark @click.stop="onClickExpenseApproval(row.item)">
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card color="grey lighten-4">
                  <v-card-title class="pb-0 title">{{ $t("expense_items") }}</v-card-title>
                  <v-card-text>
                    <v-data-table
                      hide-default-footer
                      :headers="table.subHeaders"
                      :items="item.expenseItems"
                      item-key="id"
                    >
                      <template #item="{ item }">
                        <tr>
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            {{ item.expenseType.name }}
                          </td>
                          <td>
                            {{ $t(`hr.expense.payment_type.${item.paymentType}`) }}
                          </td>
                          <td>
                            {{ item.dateTime | formattedDateTimeMinute }}
                          </td>
                          <td>
                            {{ $helpers.getConvertCurrency(item.requested) }}
                          </td>
                          <td>
                            <request-status-label :status="item.status" />
                          </td>
                          <td>
                            <file-download-button v-if="item.attachment" :url="item.publicURL" />
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <expense-approver-dialog
      v-model="dialog.status"
      :data="dialog.data"
      @success="onSuccessApproval"
      @close="onCloseDialog"
    />
  </v-row>
</template>

<script>
  import * as GQL from "./query";

  export default {
    name: "ExpenseApproval",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      ExpenseApproverDialog: () => import("./components/RequestApprover")
    },
    data: (vm) => ({
      dialog: {
        status: false,
        data: null,
        approved: false
      },
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: null,
          endDate: null
        },
        options: {
          itemsPerPage: 10,
          page: 1
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.employee_requesting"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employee_creating"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.name"),
            value: "name",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.description"),
            value: "description",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.travel"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.expense_management"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("currency.requested_amount"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.status"),
            value: "requestStatus",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          }
        ],
        subHeaders: [
          {
            text: vm.$t("global.name"),
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.type"),
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.paymentType"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.date"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("currency.requested_amount"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.status"),
            value: "requestStatus",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          }
        ]
      }
    }),
    methods: {
      getConvertedCurrencies(currencies) {
        if (Array.isArray(currencies)) {
          return currencies.map((currency) => this.$helpers.getConvertCurrency(currency));
        }
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: GQL.MANAGER_APPROVER_EXPENSE,
            variables: {
              id: this.$store.state.auth.user.id
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data =
                data.employee.pendingApprovalExpenseRequests
                  .sort((a, b) => this.$moment(b.createdDateTime).diff(a.createdDateTime))
                  .map((process) => ({ ...process.expense, process: process.id }))
                  .map((expense) => ({
                    ...expense,
                    expenseItems: expense.expenseItems.map((item) => ({
                      ...item,
                      status:
                        expense.requestStatus === "DENIED" || expense.requestStatus === "CANCELLED"
                          ? "DENIED"
                          : item.abolished === null || expense.requestStatus === "PENDING"
                          ? "PENDING"
                          : item.abolished
                          ? "DENIED"
                          : "APPROVED"
                    }))
                  })) || [];
            }
          })
          .catch(console.error)
          .finally(() => (this.table.loading = false));
      },
      onClickExpenseApproval(expense) {
        this.dialog.data = expense;
        this.dialog.status = true;
      },
      onCloseDialog() {
        this.dialog.data = null;
        this.dialog.status = false;
      },
      onSuccessApproval() {
        this.onCloseDialog();
        this.onRefreshData();
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
